var render = function render(){
  var _vm$feedData, _vm$feedData$apiValue, _vm$feedData2, _vm$feedData3, _vm$feedData4, _vm$feedData4$apiValu, _vm$feedData5, _vm$feedData6, _vm$feedData7, _vm$feedData7$explore, _vm$feedData8, _vm$feedData8$network, _vm$feedData9, _vm$feedData10, _vm$feedData11, _vm$feedData11$loader, _vm$feedData12, _vm$feedData12$apiVal, _vm$feedData13;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "feed-details"
  }, [_c('dl', {
    staticClass: "stats-grid"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Answer")]), _vm.feedData ? _c('dd', {
    staticClass: "stat-value"
  }, [!_vm.sUSDe_RATE ? _c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.parseToCurrency((_vm$feedData = _vm.feedData) === null || _vm$feedData === void 0 ? void 0 : (_vm$feedData$apiValue = _vm$feedData.apiValues) === null || _vm$feedData$apiValue === void 0 ? void 0 : _vm$feedData$apiValue.value, _vm.denominationMap[(_vm$feedData2 = _vm.feedData) === null || _vm$feedData2 === void 0 ? void 0 : _vm$feedData2.token].denomination, _vm.feedData.token))
    }
  }) : _c('strong', [_vm._v(_vm._s(_vm.currencySymbolMap[_vm.denominationMap[(_vm$feedData3 = _vm.feedData) === null || _vm$feedData3 === void 0 ? void 0 : _vm$feedData3.token].denomination] || _vm.denominationMap[_vm.feedData.token].denomination) + " "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatPriceWithoutCurrency((_vm$feedData4 = _vm.feedData) === null || _vm$feedData4 === void 0 ? void 0 : (_vm$feedData4$apiValu = _vm$feedData4.apiValues) === null || _vm$feedData4$apiValu === void 0 ? void 0 : _vm$feedData4$apiValu.value, _vm.sUSDe_RATE))
    }
  })]), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "timestamp",
    attrs: {
      "title": _vm.feedData.updateTime
    }
  }, [_vm._v("Updated " + _vm._s(_vm.feedData.humanUpdateTime))])]) : _vm._e()]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Network")]), _c('dd', {
    staticClass: "stat-value-wrapper"
  }, [_c('div', {
    staticClass: "stat-value"
  }, [_vm.feedData ? _c('img', {
    staticClass: "feeds__token-image small",
    attrs: {
      "src": (_vm$feedData5 = _vm.feedData) === null || _vm$feedData5 === void 0 ? void 0 : _vm$feedData5.network.image,
      "alt": (_vm$feedData6 = _vm.feedData) === null || _vm$feedData6 === void 0 ? void 0 : _vm$feedData6.network.name
    }
  }) : _vm._e(), _c('span', {
    staticClass: "applicant-info__text"
  }, [_c('a', {
    attrs: {
      "href": (_vm$feedData7 = _vm.feedData) === null || _vm$feedData7 === void 0 ? void 0 : (_vm$feedData7$explore = _vm$feedData7.explorer) === null || _vm$feedData7$explore === void 0 ? void 0 : _vm$feedData7$explore.explorerUrl,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s((_vm$feedData8 = _vm.feedData) === null || _vm$feedData8 === void 0 ? void 0 : (_vm$feedData8$network = _vm$feedData8.network) === null || _vm$feedData8$network === void 0 ? void 0 : _vm$feedData8$network.name) + " ")])])])])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Symbol")]), _vm.feedData.token === 'beraETH_FUNDAMENTAL' ? _c('dd', {
    staticClass: "stat-value"
  }, [_vm._v("beraSTONE_FUNDAMENTAL")]) : _c('dd', {
    staticClass: "stat-value"
  }, [_vm._v(_vm._s((_vm$feedData9 = _vm.feedData) === null || _vm$feedData9 === void 0 ? void 0 : _vm$feedData9.token))])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Deviation threshold")]), _c('dd', {
    staticClass: "stat-value"
  }, [_vm._v(_vm._s((_vm$feedData10 = _vm.feedData) === null || _vm$feedData10 === void 0 ? void 0 : _vm$feedData10.deviation))])]), _vm.feedData ? _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Heartbeat")]), _c('dd', {
    staticClass: "stat-value"
  }, [(_vm$feedData11 = _vm.feedData) !== null && _vm$feedData11 !== void 0 && (_vm$feedData11$loader = _vm$feedData11.loaders) !== null && _vm$feedData11$loader !== void 0 && _vm$feedData11$loader.blockTimestamp && ((_vm$feedData12 = _vm.feedData) === null || _vm$feedData12 === void 0 ? void 0 : (_vm$feedData12$apiVal = _vm$feedData12.apiValues) === null || _vm$feedData12$apiVal === void 0 ? void 0 : _vm$feedData12$apiVal.timestamp) == null ? _c('Loader', {
    staticClass: "feeds__loader"
  }) : _c('span', {
    staticClass: "feeds__timestamp"
  }, [_vm.heartbeatIsNumber(_vm.feedData.heartbeat) ? _c('span', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.feedData.heartbeatTitle))]), _c('to-date-counter', {
    staticClass: "ml-2",
    attrs: {
      "interval": _vm.feedData.heartbeatInterval,
      "duration": _vm.feedData.heartbeat
    }
  })], 1)]) : _c('div', [_c('span', {
    staticClass: "cron-trigger",
    attrs: {
      "id": "cron-trigger-".concat(_vm.feedData.layer_id)
    }
  }, [_c('span', [_vm._v("Cron: " + _vm._s(_vm.feedData.heartbeatTitle))]), _c('cron-counter', {
    staticClass: "ml-2",
    attrs: {
      "crons": _vm.feedData.heartbeat
    }
  })], 1)])])], 1)]) : _vm._e()]), _vm.feedData ? _c('contract-address', {
    staticClass: "contract-address",
    attrs: {
      "item": _vm.feedData,
      "disable-truncate": "",
      "separate-labels": false
    }
  }) : _vm._e(), _c('div', {
    staticClass: "chart-container"
  }, [_c('div', {
    staticClass: "feed-chart"
  }, [_vm.currentChartData && !_vm.isLoading ? _c('FeedChart', {
    ref: "feedChart",
    attrs: {
      "explorer-url": _vm.feedData.explorer.explorerUrl,
      "data": _vm.currentChartData,
      "range": _vm.currentRange,
      "denomination": _vm.denominationMap[(_vm$feedData13 = _vm.feedData) === null || _vm$feedData13 === void 0 ? void 0 : _vm$feedData13.token].denomination,
      "special-denomination": _vm.sUSDe_RATE,
      "duplicated-ranges": _vm.duplicateRanges.flat(),
      "currency": _vm.getCurrency(_vm.feedData.token),
      "maxDataPoints": 500
    },
    on: {
      "range-change": _vm.handleRangeChange
    }
  }) : _c('div', {
    staticClass: "loading-container"
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "1"
    }
  })], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }