import tokenConfig from "./config/tokens.json";
import axios from "axios";

const getManifest = async (manifestName) => {
  try {
    const { data } = await axios.get(
      `https://raw.githubusercontent.com/redstone-finance/redstone-oracles-monorepo/refs/heads/main/packages/oracle-node/manifests/data-services/${manifestName}.json`
    );
    return data;
  } catch (error) {
    console.error(
      `Fetching manifest failed, ${manifestName} couldn't be loaded`,
      { error }
    );
    return {};
  }
};

export const DEFAULT_PROVIDER = "coingecko";

let cachedManifests = null;
let symbolDetails = null;

async function initializeManifests() {
  if (cachedManifests) {
    return cachedManifests;
  }

  const [
    mainManifest,
    primaryManifest,
    primaryManifestLong,
    arbitrumManifest,
    avalancheManifest
  ] = await Promise.all([
    getManifest("main"),
    getManifest("primary"),
    getManifest("primary-long"),
    getManifest("arbitrum"),
    getManifest("avalanche")
  ]);

  const primaryManifestCombined = {
    ...primaryManifest,
    tokens: {
      ...primaryManifest.tokens,
      ...primaryManifestLong.tokens,
    },
  };

  cachedManifests = {
    coingecko: mainManifest,
    "redstone-primary-prod": primaryManifestCombined,
    "redstone-avalanche-prod": avalancheManifest,
    "redstone-arbitrum-prod": arbitrumManifest,
  };

  return cachedManifests;
}

// Modified to ensure manifests are initialized before returning
export async function getDetailsForSymbol(symbol) {
  const details = await getAllSymbolDetails();
  return details[symbol];
}

export async function getOrderedProviders() {
  const manifests = await initializeManifests();
  return ['redstone-primary-prod']
}

export async function getAllSupportedTokens() {
  return await getAllSymbolDetails();
}

async function getAllSymbolDetails() {
  if (symbolDetails) {
    return symbolDetails;
  }

  const tokenDetails = {};
  const manifestsObject = await initializeManifests();

  // Initialize with default provider
  Object.entries(tokenConfig).forEach(([symbol, config]) => {
    tokenDetails[symbol] = { ...config, providers: [DEFAULT_PROVIDER] };
  });

  // Process each manifest
  for (const [provider, manifest] of Object.entries(manifestsObject)) {
    for (const [symbol, config] of Object.entries(manifest.tokens || {})) {
      if (
        tokenDetails[symbol] &&
        tokenDetails[symbol].providers &&
        tokenDetails[symbol].providers[0] === DEFAULT_PROVIDER &&
        config.source &&
        config.source.length
      ) {
        tokenDetails[symbol].providers = [provider];
        if (!manifestsObject.coingecko.tokens[symbol]) {
          console.warn(`Missing ${symbol} in main manifest!`);
        }
      }
    }
  }

  symbolDetails = {};
  
  Object.entries(tokenDetails)
    .filter(([, config]) => config.providers[0] !== DEFAULT_PROVIDER)
    .forEach(([symbol, config]) => {
      symbolDetails[symbol] = config;
    });

  // Then add default provider tokens
  Object.entries(tokenDetails)
    .filter(([, config]) => config.providers[0] === DEFAULT_PROVIDER && config.provider !== 'redstone-arbitrum-prod')
    .forEach(([symbol, config]) => {
      symbolDetails[symbol] = config;
    });

  return symbolDetails;
}

export function isCurrencyToken(details) {
  return !details.tags?.includes("lens");
}

export function getCurrency(details) {
  if (details.name?.includes("/")) {
    const [, currency] = details.name?.split("/");
    return currency.replaceAll(" ", "-");
  }

  if (details.symbol?.includes("/")) {
    const [, currency] = details.symbol?.split("/");
    return currency.replaceAll(" ", "-");
  }

  return "USD";
}

export const getManifests = async () => {
  return await initializeManifests();
};