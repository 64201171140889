<template>
  <div class="provider-details">
    <div class="provider-info mt-2">
      <div class="d-flex justify-content-start mt-3 mb-2 provider-values">
        <div class="d-flex align-items-center">
          <div class="pull-model__status">
            <span class="feeds__status-text mr-4">
              <div><i class="fa fa-cube inline"></i> Assets <strong class="ml-2">{{
                provider && provider?.assetsCount ? provider.assetsCount : "0"
              }}</strong></div>
            </span>
          </div>
          <SourcesPicker
            v-if="tokens"
            :items="parseJSON(sourcesData)"
            :tokens="tokens"
            @input="handleFilter('sources', $event)"
            :value="selectedSources"
            class="ml-3"
          />
        </div>
        <div
          class="pull-model__pagers text-light fw-normal"
          style="margin-left: auto"
        >
          <span class="feeds__status-text">
            Per page:
            <select
              v-model="perPage"
              @change="onPerPageChange"
              class="feeds__select"
            >
              <option
                v-for="option in perPageOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </span>
          <span class="feeds__status-text" style="text-align: right">
            Current page:
            <select
              v-model="currentPage"
              @change="onSelectedPageChange"
              class="feeds__select"
            >
              <option v-for="page in availablePages" :key="page" :value="page">
                {{ page }}
              </option>
            </select>
          </span>
        </div>
      </div>
    </div>
    <div>
      <div class="feeds__actions-wrapper-item">
        <div v-if="selectedSources?.length > 0">
          <div class="selected-items">Selected sources:</div>
          <div class="selected-filters-icons">
            <SelectedFilters
              class=""
              @remove="removeSource"
              :filters="displayedSelectedSources"
            />
          </div>
        </div>
      </div>
      <div
        v-if="hasFiltersAndSearch"
        class="clear-filters"
        @click="resetFilters"
      >
        Clear all
      </div>
    </div>
    <hr />
    <div>
      <div class="loading-container" v-if="isLoadingTokens">
        <vue-loaders-ball-beat color="var(--redstone-red-color)" scale="1" />
      </div>
      <b-table
        id="assets-table"
        ref="assetsTable"
        stacked="md"
        hover
        :items="tokens"
        :fields="fieldsFiltered"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filters"
        :filter-function="customFilter"
        @filtered="onFiltered"
        v-if="dataServiceId !== 'redstone-custom-urls-demo' && !isLoadingTokens"
      >
        <template #cell(name)="data">
          <img
            class="token-logo"
            :src="
              data.item.logoURI ||
              findLogoForSource(data.item.symbol) ||
              logoPlaceholder
            "
          />
          <RouterLink
            :to="{
              name: 'TokenPage',
              params: {
                symbol: data.item.symbol.includes('/')
                  ? data.item.symbol.replace('/', '-')
                  : data.item.symbol,
              },
            }"
          >
            <span class="token-name ml-3">{{ data.item.name }}</span>
          </RouterLink>
        </template>
        <template #cell(symbol)="data">
          <span
            class="text-truncate d-block"
            v-b-tooltip.hover
            :title="data.item.symbol"
          >
            {{ data.item.symbol }}
          </span>
        </template>
        <template #cell(sources)="data">
          <div
            class="d-flex source-links-wrapper"
            :ref="'symbols_' + data.item.symbol"
          >
            <div class="d-flex source-links">
              <a
                class="source-link mb-2 mb-md-0"
                target="_blank"
                :href="source.url"
                v-bind:key="source.symbol"
                v-for="source in data.item.source"
              >
                <img
                  class="source-logo"
                  :src="
                    source.logoURI ||
                    findLogoForSource(source.name) ||
                    logoPlaceholder
                  "
                  v-b-tooltip.hover
                  :title="source.name"
                />
              </a>
            </div>
          </div>
        </template>
      </b-table>
      <b-pagination
        :prev-text="prevIcon"
        :next-text="nextIcon"
        limit="1"
        @change="onPageChange"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        class="my-3 custom-pagination"
      >
        <template #page="{ page }">
          <span v-if="page === currentPage" class="entry-info">
            <span v-if="totalRows > 0"
              >Showing {{ firstEntry }} to {{ lastEntry }} of
              {{ totalRows }} entries</span
            >
            <span v-else>
              No entries found
              <span v-if="hasFiltersAndSearch"> - </span>
              <span
                style="pointer-events: all"
                v-if="hasFiltersAndSearch"
                class="clear-filters"
                @click="resetFilters"
              >
                Clear filters
              </span>
            </span>
          </span>
          <span v-else>{{ page }}</span>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
  import { findLogoForSource } from "./logosHelper";
  import { mapState } from "vuex";
  import LabelValue from "@/components/DataService/LabelValue";
  import sourcesData from "../../config/sources.json";
  import _ from "lodash";
  import { getDetailsForSymbol } from "@/tokens";
  import { RouteParamsHandler } from "@/core/RouteParamsHandler";
  import SourcesPicker from "./SourcesPicker.vue";
  import SelectedFilters from "@/pages/Redstone/Feeds/components/SelectedFilters.vue";

  export default {
    name: "DataService",
    components: {
      SelectedFilters,
      LabelValue,
      SourcesPicker,
    },
    props: {
      provider: {},
    },
    data() {
      return {
        sourcesData,
        popularTokens: [
          "BTC",
          "ETH",
          "USDT",
          "USDC",
          "BNB",
          "SOL",
          "XRP",
          "ADA",
          "AVAX",
          "MATIC",
          "DAI",
          "DOT",
          "LINK",
          "UNI",
          "AAVE",
          "APE",
        ],
        fields: [
          {
            key: "name",
            sortable: true,
            label: "Asset",
            thStyle: { width: "300px" },
            sortByFormatted: true,
          },
          {
            key: "symbol",
            sortable: true,
            label: "Symbol",
            thStyle: { width: "200px" },
            sortByFormatted: true,
          },
          "sources",
        ],
        firstManifest: null,
        transactionTime: null,
        tokens: null,
        tokenDetailsCache: new Map(),
        selectedSources: [],
        logoPlaceholder:
          "https://raw.githubusercontent.com/redstone-finance/redstone-images/main/redstone-logo.png",
        currentPage: 1,
        perPage: 16,
        routeParamsHandler: null,
        perPageOptions: [8, 16, 32, 64],
        filteredItems: [],
        isLoadingTokens: false,
      };
    },
    computed: {
      ...mapState("layout", ["searchTerm"]),
      displayedSelectedSources() {
        return this.selectedSources.map((source) => ({
          key: source,
          name: source,
          imageUrl: this.parseJSON(this.sourcesData).find(
            (dataSource) => dataSource.id.split("-")[0] === source
          ).logoURI,
        }));
      },
      filters() {
        return {
          searchTerm: this.searchTerm,
          sources: this.selectedSources,
        };
      },
      totalRows() {
        return this.hasFiltersAndSearch
          ? this.filteredItems?.length
          : this.tokens?.length;
      },
      firstEntry() {
        return this.totalRows === 0
          ? 0
          : (this.currentPage - 1) * this.perPage + 1;
      },
      lastEntry() {
        return Math.min(this.currentPage * this.perPage, this.totalRows);
      },
      availablePages() {
        return Array.from(
          { length: Math.ceil(this.totalRows / this.perPage) },
          (_, i) => i + 1
        );
      },
      currentManifest() {
        return this.provider?.currentManifest;
      },
      dataServiceId() {
        return this.$route.params.id;
      },
      fieldsFiltered() {
        return this.fields;
      },
      prevIcon() {
        return "Previous";
      },
      nextIcon() {
        return "Next";
      },
      hasFiltersAndSearch() {
        return (
          this.searchTerm?.trim()?.length > 0 ||
          this.selectedSources?.length > 0 ||
          false
        );
      },
    },
    methods: {
      async fetchTokenDetails(symbol) {
        if (this.tokenDetailsCache.has(symbol)) {
          return this.tokenDetailsCache.get(symbol);
        }
        try {
          const details = await getDetailsForSymbol(symbol);
          this.tokenDetailsCache.set(symbol, details);
          return details;
        } catch (error) {
          console.error(`Error fetching details for token ${symbol}:`, error);
          return null;
        }
      },
      async prepareTokensDataForTable() {
        if (!this.currentManifest?.tokens) {
          this.tokens = [];
          return;
        }
        this.isLoadingTokens = true;
        try {
          const tokenPromises = Object.entries(this.currentManifest.tokens).map(
            async ([symbol, detailsInManifest]) => {
              const tokenInfo = await this.fetchTokenDetails(symbol);
              const sourceList =
                detailsInManifest.source || this.currentManifest.defaultSource;
              // Add popularity ranking
              const popularityIndex = this.popularTokens.indexOf(symbol);
              return {
                logoURI: tokenInfo?.logoURI,
                symbol,
                name:
                  tokenInfo?.name ||
                  `${symbol.replace("_FUNDAMENTAL", "").replace("_RATE_PROVIDER", "")} contract value`,
                source: sourceList.map((el) => ({
                  name: el,
                  ...this.sourcesData[el],
                  ...this.sourcesData[this.removeContentAfterLastDash(el)],
                })),
                // Add popularity field (-1 for non-popular tokens)
                popularity:
                  popularityIndex === -1
                    ? Number.MAX_SAFE_INTEGER
                    : popularityIndex,
              };
            }
          );
          const tokenData = await Promise.all(tokenPromises);
          // Sort by popularity before assigning to this.tokens
          this.tokens = tokenData.sort((a, b) => a.popularity - b.popularity);
        } catch (error) {
          console.error("Error preparing tokens data:", error);
          this.tokens = [];
        } finally {
          this.isLoadingTokens = false;
        }
      },
      removeSource(item) {
        this.selectedSources = this.selectedSources.filter(
          (source) => source != item
        );
        this.handleFilter("sources", this.selectedSources);
      },
      findLogoForSource,
      handleFilter(filterType, value) {
        if (filterType === "sources") {
          this.selectedSources = value;
          this.$store.dispatch("layout/updateSearchTerm", "");
        }
        this.currentPage = 1;
        this.applyFilters();
        this.updateRouteParams();
      },
      onPerPageChange() {
        this.currentPage = 1;
        this.updateRouteParams();
      },
      onSelectedPageChange() {
        this.updateRouteParams();
      },
      removeContentAfterLastDash(str) {
        const lastDashIndex = str.lastIndexOf("-");
        return lastDashIndex === -1 ? str : str.substring(0, lastDashIndex);
      },
      formatInterval(interval) {
        return interval / 1000 + " s";
      },
      parseJSON(data) {
        try {
          return Object.entries(data).map(([key, value]) => ({
            ...value,
            id: key,
          }));
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return [];
        }
      },
      customFilter(row, filters) {
        if (filters.searchTerm?.length > 0) {
          const searchLower = filters.searchTerm?.toLowerCase();
          return (
            row.name?.toLowerCase().includes(searchLower) ||
            row.symbol?.toLowerCase().includes(searchLower)
          );
        }
        const sourceMatch =
          filters.sources?.length === 0 ||
          filters.sources.some((filterSource) =>
            row.source.some((rowSource) => {
              const idParts = rowSource.name.split("-");
              return filterSource === idParts[0];
            })
          );
        return sourceMatch;
      },
      resetFilters(clearSearch = true) {
        if (clearSearch) {
          this.$store.dispatch("layout/updateSearchTerm", "");
        }
        this.currentPage = 1;
        this.selectedSources = [];
        this.updateRouteParams();
        this.$store.dispatch("layout/updateFeedsFilterStatus", false);
      },
      applyFilters() {
        this.$nextTick(() => {
          this.$refs.assetsTable?.refresh();
        });
        if (this.hasFilters) {
          this.$store.dispatch("layout/updateFeedsFilterStatus", true);
        }
      },
      onFiltered(filteredItems) {
        this.filteredItems = filteredItems;
      },
      onPageChange(page) {
        this.currentPage = page;
        this.updateRouteParams();
      },
      updateRouteParams() {
        this.routeParamsHandler.updateRouteParams({
          currentPage: this.currentPage,
          perPage: this.perPage,
          searchTerm: this.searchTerm,
          selectedSources: this.selectedSources,
        });
      },
      async initializeFromRoute() {
        const routeParams =
          this.routeParamsHandler.initializeFiltersFromRoute();
        this.currentPage = routeParams.currentPage;
        this.perPage = routeParams.perPage;
        this.selectedSources = routeParams.selectedSources;
        await this.$store.dispatch(
          "layout/updateSearchTerm",
          routeParams.searchTerm || ""
        );
      },
    },
    async created() {
      document.addEventListener("scroll", this.scrollFunction);
      if (this.currentManifest) {
        await this.prepareTokensDataForTable();
      }
    },
    async mounted() {
      this.routeParamsHandler = new RouteParamsHandler(
        this.$router,
        {
          pageParam: "page",
          searchParam: "search",
        },
        16
      );
      await this.initializeFromRoute();
      this.$nextTick(() => {
        this.routeParamsHandler.setInitialLoadComplete();
      });
    },
    watch: {
      currentManifest: {
        immediate: true,
        handler: async function () {
          if (this.currentManifest) {
            await this.prepareTokensDataForTable();
          }
        },
      },
      searchTerm: {
        handler(newValue) {
          this.applyFilters();
          this.updateRouteParams();
          if (this.searchTerm === "") {
            this.$store.dispatch("layout/updateFeedsFilterStatus", true);
          } else if (newValue?.length >= 3) {
            this.resetFilters(false);
            this.$store.dispatch("layout/updateFeedsFilterStatus", false);
          }
        },
      },
    },
    beforeDestroy() {
      document.removeEventListener("scroll", this.scrollFunction);
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/styles/app";
  .provider-details {
    .token-logo {
      width: 40px;
      height: 40px;
      padding: 5px;
      box-shadow: var(--content-shadow);
      border-radius: 40px;
      border: 2px solid #ebebeb;
      margin: 10px;
    }

    .loading-container {
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .provider-www,
    .provider-description {
      margin-left: 10px;
    }

    .provider-description {
      font-weight: $font-weight-normal;
    }

    .provider-www {
      font-weight: $font-weight-soft-bold;

      i {
        transform: translate(3px, 1px);
      }
    }
  }

  .provider-values {
    margin-left: 10px;

    & > div {
      flex: 0 0 13%;
    }

    @media (max-width: breakpoint-max(sm)) {
      flex-wrap: wrap;

      & > div {
        flex: 0 0 50%;
      }
    }
  }

  .token-name {
    font-size: 14px;
    font-weight: $font-weight-soft-bold;
  }

  hr {
    border-top: 1px solid $gray-300;
  }

  .table-title {
    margin-left: 10px;
    color: $navy;
    font-size: 20px;
    font-weight: $font-weight-soft-bold;
  }

  .source-link {
    min-width: 30px;
    display: inline-block;
    text-align: center;
  }

  .source-logo {
    height: 25px;
    margin: 4px;
    border-radius: 50%;
  }

  .text-preloader {
    width: 350px;
    @include preload-animation(2.5s, 350px);

    &:first-of-type {
      height: 16px;
      margin-bottom: 6px;
    }

    &:nth-of-type(2) {
      height: 16px;
      margin-bottom: 5px;
    }
  }

  .token-preloader {
    height: 35px;
    margin-bottom: 20px;
    border-radius: 3px;
    @include preload-animation(2.5s, 100vw);
  }
</style>

<style lang="scss">
  @import "~@/styles/app";

  .pull-model {
    font-size: 14px;
    background-color: $gray-100;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: var(--content-shadow);

    &__pagers {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
      span {
        text-align: center !important;
      }
      select {
        width: 100px;
      }
    }
    &__view-details {
      padding: 0 10px;
      margin-bottom: 30px;
      h1 {
        font-size: 18px;
      }
    }
    &__status {
      display: flex;
      flex-flow: row;
      align-items: center;
      border-radius: 10px;
      border: 1px solid rgb(227, 227, 227);
      background-color: $gray-200;
      // box-shadow: var(--content-shadow);
      padding: 10px;
      div {
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      strong {
        font-size: 15px;
      }
    }
    table {
      border-top: 1px solid rgb(227, 227, 227);
    }

    thead {
      tr {
        background: #f4f4f4;
      }

      th {
        background-position: 30px !important;
        padding: 30px 15px !important;
        text-align: center;
        border: 1px solid rgb(227, 227, 227) !important;
        text-transform: capitalize !important;
        font-size: 12px !important;
      }
    }

    tbody tr {
      &:hover {
        background: #fff !important;
      }

      &:nth-child(even) {
        background: #f4f4f4 !important;
      }

      padding: 10px !important;

      td {
        text-align: center;
        border-top: 1px solid rgb(227, 227, 227);
        border-bottom: 1px solid rgb(227, 227, 227);
        padding: 20px 10px;
        &:first-child {
          text-align: left;
        }
        &:nth-child(3) {
          // second is taken by popularity
          text-align: left;
        }

        @media (min-width: breakpoint-min(lg)) {
          &:first-child {
            border-left: 1px solid rgb(227, 227, 227);
          }

          &:last-child {
            border-right: 1px solid rgb(227, 227, 227);
          }
        }
      }
    }

    &__table {
      font-size: 14px;
    }

    @media (max-width: breakpoint-min(md)) {
      &__table {
        border: 0;

        thead {
          display: none;
        }

        tbody {
          tr {
            border: 1px solid #ced4da;
            margin-bottom: 1rem;
            display: block;
            border-radius: 5px;
            &:nth-child(even) {
              background-color: #fff !important;
            }

            td {
              display: block;
              text-align: left;
              padding: 15px 0 !important;
              position: relative;
              border: 0 !important;
              div {
                width: 100% !important;
              }
              &::before {
                content: attr(data-label);
                text-align: left;
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    // .table.b-table > tbody > tr > [data-label]::before {
    //   content: attr(data-label);
    // }

    .disable-sorting {
      thead {
        th:nth-child(5) {
          pointer-events: none !important;
        }
      }
    }

    .table-loader {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      padding: 100px 0;
    }
  }

  .label-value {
    .value {
      color: $gray-750;
      font-weight: $font-weight-normal;
    }

    .label {
      font-weight: $font-weight-soft-bold;
      color: $navy;
    }
  }

  .provider-details #assets-table {
    table-layout: fixed;

    th {
      text-transform: none;
      color: $navy;
      font-size: 12px;
      font-weight: $font-weight-soft-bold;
    }

    th:nth-of-type(1) {
      width: 250px;
    }

    th:nth-of-type(2) {
      width: 100px;
    }

    th:nth-of-type(3) {
      width: fit-content;
    }

    th:nth-of-type(4) {
      width: 250px;
    }

    th:nth-of-type(2) {
      overflow: hidden;
    }

    td .source-links {
      overflow: hidden;
    }

    td {
      .source-links {
        flex-wrap: wrap;
      }
    }
  }
</style>
