import tokenConfig from "@/config/tokens.json";
import axios from "axios";

const GITHUB_BASE_URL = "https://raw.githubusercontent.com/redstone-finance/redstone-oracles-monorepo/refs/heads/main/packages/oracle-node/manifests/data-services";
const DEFAULT_PROVIDER = "coingecko";

export default {
  namespaced: true,
  
  state: {
    manifestsLoadComplete: false,
    manifests: {},
    symbolDetails: null,
    cachedManifests: null
  },

  getters: {
    isLoadComplete: state => state.manifestsLoadComplete,
    getAllManifests: state => state.manifests,
    getSymbolDetails: state => state.symbolDetails,
    denominationMap: state => {
      const denominationMap = {};
      if (state.symbolDetails) {
        Object.keys(state.symbolDetails)?.forEach((symbol) => {
          denominationMap[symbol] = {
            rateFeed: state.symbolDetails[symbol].config?.rateFeed,
            denomination: state.symbolDetails[symbol].config?.denomination,
          };
        });
      }
      return denominationMap;
    },
    isCurrencyToken: () => (details) => !details.tags?.includes("lens"),
    getCurrency: () => (details) => {
      if (details.name?.includes("/")) {
        const [, currency] = details.name?.split("/");
        return currency.replaceAll(" ", "-");
      }
      if (details.symbol?.includes("/")) {
        const [, currency] = details.symbol?.split("/");
        return currency.replaceAll(" ", "-");
      }
      return "USD";
    }
  },

  mutations: {
    SET_MANIFESTS(state, manifests) {
      state.manifests = { ...state.manifests, ...manifests };
    },
    SET_LOAD_COMPLETE(state, value) {
      state.manifestsLoadComplete = value;
    },
    SET_SYMBOL_DETAILS(state, details) {
      state.symbolDetails = details;
    },
    SET_CACHED_MANIFESTS(state, manifests) {
      state.cachedManifests = manifests;
    }
  },

  actions: {
    async fetchManifest({ commit }, manifestName) {
      try {
        const { data } = await axios.get(`${GITHUB_BASE_URL}/${manifestName}.json`);
        return data;
      } catch (error) {
        console.error(`Fetching manifest failed, ${manifestName} couldn't be loaded`, { error });
        return {};
      }
    },

    async initializeManifests({ state, commit, dispatch }) {
      if (state.cachedManifests) {
        return state.cachedManifests;
      }

      const [
        mainManifest,
        primaryManifest,
        primaryManifestLong,
        arbitrumManifest,
        avalancheManifest
      ] = await Promise.all([
        this.dispatch('manifests/fetchManifest', 'main'),
        this.dispatch('manifests/fetchManifest', 'primary'),
        this.dispatch('manifests/fetchManifest', 'primary-long'),
        this.dispatch('manifests/fetchManifest', 'arbitrum'),
        this.dispatch('manifests/fetchManifest', 'avalanche')
      ]);

      dispatch('setLoadComplete');

      const primaryManifestCombined = {
        ...primaryManifest,
        tokens: {
          ...primaryManifest.tokens,
          ...primaryManifestLong.tokens,
        },
      };

      const manifests = {
        "redstone-primary-demo": mainManifest,
        "redstone-primary-prod": primaryManifestCombined,
        "redstone-avalanche-prod": avalancheManifest,
        "redstone-arbitrum-prod": arbitrumManifest,
      };

      commit('SET_CACHED_MANIFESTS', manifests);
      commit('SET_MANIFESTS', manifests);
      return manifests;
    },

    async getAllSymbolDetails({ state, commit, dispatch }) {
      if (state.symbolDetails) {
        return state.symbolDetails;
      }

      const tokenDetails = new Map();
      const manifestsObject = await dispatch('initializeManifests');

      // Initialize default provider tokens
      Object.entries(tokenConfig).forEach(([symbol, config]) => {
        tokenDetails.set(symbol, {
          ...config,
          providers: [DEFAULT_PROVIDER],
          config: {}
        });
      });

      // Single pass through manifests
      Object.entries(manifestsObject).forEach(([provider, manifest]) => {
        const tokens = manifest.tokens || {};
        Object.entries(tokens).forEach(([symbol, config]) => {
          const existing = tokenDetails.get(symbol) || { 
            providers: ['redstone-primary-prod'], 
            config: {} 
          };

          if (provider !== DEFAULT_PROVIDER && config.source?.length && provider !== 'redstone-arbitrum-prod') {
            existing.providers = ['redstone-primary-prod'];
          }

          existing.config = { ...existing.config, ...config };
          tokenDetails.set(symbol, existing);
        });
      });

      // Convert to object and sort
      const sortedDetails = [...tokenDetails.entries()]
        .sort(([, a], [, b]) => 
          (a.providers[0] === DEFAULT_PROVIDER) - (b.providers[0] === DEFAULT_PROVIDER)
        )
        .reduce((acc, [symbol, config]) => {
          acc[symbol] = config;
          return acc;
        }, {});

      commit('SET_SYMBOL_DETAILS', sortedDetails);
      return sortedDetails;
    },

    async getDetailsForSymbol({ dispatch }, symbol) {
      const details = await dispatch('getAllSymbolDetails');
      return details[symbol];
    },

    async getOrderedProviders({ dispatch }) {
      const manifests = await dispatch('initializeManifests');
      return Object.keys(manifests);
    },

    setLoadComplete({ commit }) {
      commit('SET_LOAD_COMPLETE', true);
    }
  }
};
