import { formatPriceWithoutCurrency } from "../../utils/FeedsTableDataLayer";
import { format as dateFnsFormat } from "date-fns";

export default (vm) => ({
  chart: {
    type: "area",
    height: "100%",
    animations: { enabled: false },
    background: "#ffffff",
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: false,
        reset: true,
      },
      autoSelected: "zoom",
      style: {
        cursor: "pointer",
      },
    },
    zoom: {
      enabled: true,
      allowMouseWheelZoom: false,
      autoScaleYaxis: true,
      zoomType: "x",
      toolbar: {
        autoSelected: false,
      },
      mouseWheelZoom: false,
      zoomedArea: {
        fill: {
          color: "rgba(253, 98, 122, 0.1)",
        },
        stroke: {
          color: "#AE0822",
        },
      },
    },
    selection: {
      enabled: true,
      fill: {
        color: "rgba(253, 98, 122, 0.1)",
      },
      stroke: {
        color: "#AE0822",
        width: 1,
      },
      xaxis: {},
      yaxis: {},
    },
    events: {
      click: function (event, chartContext, config) {
        if (config.dataPointIndex >= 0) {
          const now = Date.now();
          const lastClick = chartContext.lastClickTime || 0;
          chartContext.lastClickTime = now;

          if (now - lastClick < 300) {
            const dataPoint =
              chartContext.w.config.series[config.seriesIndex].data[
                config.dataPointIndex
              ];
            if (dataPoint.txHash) {
              window.open(`${vm.explorerUrl}/tx/${dataPoint.txHash}`, "_blank");
            }
          } else {
            chartContext.toggleFixedTooltip?.(config.dataPointIndex);
          }
        } else {
          chartContext.hideTooltip?.();
        }
      },
    },
  },
  stroke: {
    curve: "straight",
    width: 2,
    colors: ["rgb(174, 8, 34)"],
  },
  colors: ["rgb(174, 8, 34)"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [20, 100, 100],
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: 5,
    borderColor: "#f1f1f1",
    padding: {
      top: 0,
      right: 10,
      bottom: 0,
      left: 10,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
        opacity: 0.1,
      },
    },
  },
  markers: {
    size: vm.processedData?.length < vm.maxDataPoints ? 2 : 0,
    strokeColors: "rgb(174, 8, 34)",
    strokeWidth: 1,
    strokeOpacity: 1,
    fillOpacity: 1,
    hover: {
      size: 6,
      sizeOffset: 3,
      fillColor: "rgb(174, 8, 34)",
      strokeColor: "#ffffff",
      strokeWidth: 2,
    },
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: "none",
      },
    },
  },
  tooltip: {
    enabled: true,
    shared: true,
    followCursor: true,
    intersect: false,
    theme: "light",
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
      formatter: (val) => {
        return dateFnsFormat(new Date(val), "MMM dd, yyyy HH:mm:ss");
      },
    },
    y: {
      title: {
        formatter: () => "",
      },
      formatter: (val) => {
        return `${vm.formattedCurrency} ${formatPriceWithoutCurrency(val, vm.specialDenomination)}`;
      },
    },
    custom: ({ seriesIndex, dataPointIndex, w }) => {
      const data = w.config.series[seriesIndex].data[dataPointIndex];
      const timestamp = dateFnsFormat(
        new Date(data.x),
        "MMM dd, yyyy HH:mm:ss"
      );
      const value = `${vm.formattedCurrency} ${formatPriceWithoutCurrency(data.y, vm.specialDenomination)}`;
      const txHash = data.txHash || "";
      const truncatedHash = txHash
        ? `${txHash.slice(0, 10)}...${txHash.slice(-10)}`
        : "";

      return `
        <div class="apexcharts-tooltip-box" style="padding: 8px;">
          <div style="margin-bottom: 4px; color: #666; font-size: 12px;">${timestamp}</div>
          <div style="margin-bottom: 4px; font-size: 16px; font-weight: 600; color: #000;">${value}</div>
          ${
            txHash
              ? `
            <div style="cursor: pointer; color: #AE0822; font-size: 12px;">
              ${truncatedHash}
              <div style="font-size: 10px; color: #666; margin-top: 2px;">Double-click point to open transaction</div>
            </div>
          `
              : ""
          }
        </div>
      `;
    },
    style: {
      fontSize: "12px",
      fontFamily: "inherit",
    },
    marker: {
      show: true,
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
  },
  crosshairs: {
    show: true,
    position: "front",
    stroke: {
      color: "#AE0822",
      width: 1,
      dashArray: 3,
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      formatter: (val) => dateFnsFormat(new Date(val), vm.getTimeFormat()),
      style: {
        colors: "#999999",
        fontSize: "11px",
      },
      datetimeUTC: false,
    },
    tooltip: {
      enabled: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      formatter: (val) => {
        return `${vm.formattedCurrency} ${formatPriceWithoutCurrency(val, vm.specialDenomination)}`;
      },
      style: {
        colors: "#999999",
        fontSize: "11px",
      },
    },
    tickAmount: 6,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: true,
      position: "front",
      stroke: {
        color: "#AE0822",
        width: 1,
        dashArray: 3,
      },
    },
    floating: false,
  },
});
